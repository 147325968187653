import React, { useState, useEffect } from 'react'
import {useParams, Link} from 'react-router-dom'
import QuantityBtn from './QuantityBtn';
import Title from './Title'
export default function ProductDetail() {
    let params = useParams();
    let [productDetail, setProductDetail] = useState(null)

    useEffect(() => {
      //1. 無第二個參數 : component 每次render都會觸發
      //2. Dependency Array 是空array時 : 只會在第一次網頁render時才會觸發
      //3. Dependency Array 是有變數時 : 第一次網頁render時 ＋ 指定的變數改變才會觸發
      fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
          .then(response => response.json())
          .then(data => {
              let productInfo = data.find(e => {
                  return e.id === parseInt(params.id)
              })
              setProductDetail(productInfo)
          })
    },[params.id]) // <== Dependency Array
  return (
    <>
      { 
        productDetail &&
        <div className="ProductDetail">
          <Title mainTitle={ productDetail.name + "產品資料"}/>
          <table width="100%">
            <tbody>
              <tr>
                <td align="right">
                  <img src={process.env.PUBLIC_URL + "/images/" +productDetail.image} alt={productDetail.name} width="400px"/>
                </td>
                <td width="45%" padding="10">
                  <p>名稱 : { productDetail.name }</p>
                  <p>售價 : { productDetail.price }元</p>
                  <p>描述 : { productDetail.description }</p>
                  <QuantityBtn productInfo={productDetail}/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
      <Link to="/" >
        <div className="backToGoodsListBtn">↩️ 返回商品列表</div>
      </Link>
    </>
  )
}