import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from './CartContext'
import QuantityBtn from './QuantityBtn'
import Title from './Title'
export default function Checkout() {

    let {cartItems} = useContext(CartContext)

    let cartEmpty = cartItems.length <= 0 ? true : false

    let grandTotal = cartItems.reduce((total, product) => {
        return total += product.price * product.quantity
    },0)

    const freeShippingPrice = 99;
  return (
    <>
        <Title mainTitle="你的購物車"/>
        {
            cartEmpty &&
            <div className="nothingInCart">
                你的購物車沒有商品<br/><br/>
                <Link to="/">去商品頁面看看吧</Link>
            </div>
        }

        {
            !cartEmpty &&
            <div className="container">
                <div className="cartSection">
                    <table className="checkoutTable">
                        <tbody>
                            {
                                /*產品列表 */
                                cartItems.map(product => 
                                    <tr key={product.id}>
                                        <td>
                                            <Link to={'/product/'+product.id}>
                                                <img src={process.env.PUBLIC_URL + "/images/" +product.image} alt={product.name} width="300px"/>
                                            </Link>
                                        </td>
                                        <td>
                                            <p>名稱 : { product.name }</p>
                                            <p>售價 : { product.price }玩</p>
                                            <p>描述 : { product.description }</p>
                                        </td>
                                        <td width="200">
                                            <QuantityBtn productInfo={product}/>
                                        </td>
                                        <td>
                                            <div className="productSubTotal">
                                                ${product.price * product.quantity}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="checkoutSection">
                    {/*價錢總數 */}
                    <div>全部商品總數:</div>
                    <div>{grandTotal}元</div>
                    {
                        /*顯示免費送貨金額 */
                        grandTotal >= freeShippingPrice ?
                        <div className="freeShipping">✔️我們免費送貨</div> 
                        :
                        <div className="noShipping">滿${freeShippingPrice}免費送貨<br/>還差${freeShippingPrice-grandTotal}</div>
                    }
                    <button>結帳付款</button>
                </div>
            </div>
        }
    </>
  )
}
