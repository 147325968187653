import React, {useState,  useEffect}  from 'react'
import { Link } from 'react-router-dom'
import QuantityBtn from './QuantityBtn'
import Title from './Title'

// import styles from './ProductList.module.css'
export default function ProductList() {

    let [productList, setProductList] = useState([])
    useEffect(() => {
        //1. 無第二個參數 : component 每次render都會觸發
        //2. Dependency Array 是空array時 : 只會在第一次網頁render時才會觸發
        //3. Dependency Array 是有變數時 : 第一次網頁render時 ＋ 指定的變數改變才會觸發
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => setProductList(data))
    },[]) // <== Dependency Array

  return (
    // 因為最外層的div是多餘, 所以最好使用React Fragment
    // React Fragment = <> 簡寫
    <>
        <Title mainTitle="React入門水果店"/>
        <div className="container">
            {
                productList.map(product => (
                    <React.Fragment key={product.id}>
                        <div className="containerItem">
                            <Link to={"/product/" + product.id}>
                                <img src={process.env.PUBLIC_URL+'/images/'+product.image} alt={product.name}/><br/>
                            </Link>
                            <div className="productName">
                                {product.name} - {product.price}元/件
                            </div>
                            <QuantityBtn productInfo={product}/>
                        </div>
                    </React.Fragment>
                ))
            }
        </div>
    </>
  )
}
