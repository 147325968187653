import React from 'react'

export default function Title({mainTitle}) {
  return (
    <>
        <h1 style={{ textAlign: "center" }}>
            {mainTitle}
        </h1>
    </>
  )
}
